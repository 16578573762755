import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import {
  getManIcon,
  getProfitsIcon,
  getShieldIcon,
} from "../../helpers/IconHelper";
import "../WelcomeComponent.css";
import WelcomeComponentList from "../WelcomeComponentList";
import "./LaunchComponent.css";
import LaunchSlideComponent from "./LaunchSlideComponent";
import MobileLaunchComponent from "./MobileLaunchComponent";
import TeamBadge from "./team-badge.component";

export default class LaunchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUnSupportedBrowser: true,
    };
  }

  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      // add app insights here
    }
    const useragent = navigator.userAgent;
    const isChrome =
      useragent &&
      (useragent.includes("Chrome/") || useragent.includes("Safari/")) &&
      !useragent.includes("Edg/");
    this.setState({ isUnSupportedBrowser: isChrome !== true });
  }

  render() {
    if (this.props.isMobile) {
      return <MobileLaunchComponent />;
    }
    return (
      <div id="launch-component">
        <div>
          <div className="title topMargin1P">Expense Hut</div>

          <div className={"topRightFixed"} style={{ textAlign: "center" }}>
            <Link to="/register" className="bigButtonGreen">
              Register - Free!
            </Link>
          </div>
        </div>

        <div>
          <div className="launch-tagline">Personal finance dashboard</div>

          <TeamBadge />

          <hr />

          <LaunchSlideComponent isMobile={false} />

          <hr />

          <WelcomeComponentList />

          <hr />

          <div className="body-footer">
            <div className={`body-footer-item`}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="exhut type-less" src={getManIcon()} />
                </ListItemAvatar>
                <ListItemText
                  primary="Multiple apps. One identity."
                  primaryTypographyProps={{ fontSize: "larger" }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {"Use same credentials to access all our apps."}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>

              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="exhut type-less" src={getProfitsIcon()} />
                </ListItemAvatar>
                <ListItemText
                  primary="Awesome features for free!"
                  primaryTypographyProps={{ fontSize: "larger" }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {
                          "Sign up to experience the great features without paying a dime."
                        }
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>

              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="exhut type-less" src={getShieldIcon()} />
                </ListItemAvatar>
                <ListItemText
                  primary="Data security"
                  primaryTypographyProps={{ fontSize: "larger" }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {"All our systems behind secured protocols."}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LaunchComponent.propTypes = {
  isMobile: PropTypes.bool,
};
