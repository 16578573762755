import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

export default function DYKComponent({ width }) {
  return (
    <div style={{ width, backgroundColor: "var(--fnx-light-grey3)" }}>
      <div className="divCenter">
        <h3>Did you know?</h3>
      </div>

      <div className="divCenter">
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="exhut type-less" src="/assets/icons/cityscape.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Connect to your Bank!"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  ></Typography>
                  {
                    "Sit back and relax while we pull your transactions and add your expenses automatically."
                  }
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />

          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                alt="exhut type-less"
                src="/assets/icons/util-mobile.svg"
              />
            </ListItemAvatar>
            <ListItemText
              primary="Check balance with sms"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  ></Typography>
                  {
                    "Just text BAL to check your balance across all credit cards."
                  }
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />

          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="exhut scan receipts" src="/assets/receipt.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Scan Receipts"
              secondary={
                <React.Fragment>
                  {"Use our mobile apps to scan receipts."}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="exhut analysis" src="/assets/icons/wallet.svg" />
            </ListItemAvatar>
            <ListItemText
              primary="Wallet"
              secondary={
                <React.Fragment>
                  Need to split expenses between friends or family? Use our{" "}
                  <a
                    href="https://www.wallet.expensehut.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Wallet
                  </a>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />

          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="exhut type-less" src="/assets/clock.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Types Less!"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  ></Typography>
                  {
                    " Use our intel mode to create an expense in as little as 5 secs."
                  }
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="exhut analysis" src="/assets/savings.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Live Currency Rates"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  ></Typography>
                  {
                    "Check how much your savings in worth as per foreign exchange rates in real time."
                  }
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />

          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="exhut analysis" src="/assets/analysis.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Analysis"
              secondary={
                <React.Fragment>
                  {" Analyse your spending pattern through multiple views."}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
      </div>
    </div>
  );
}
