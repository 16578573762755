import React from "react";
import { Link } from "react-router-dom";

export default function MobileAvertisementListComponent() {
  return (
    <>
      <div className="divCenterAlign" style={{ flexDirection: "column" }}>
        <div className="divColumn">
          <a
            href="https://recruit.expensehut.com"
            target="_blank"
            rel="noreferrer"
          >
            <h1>Recruit</h1>
          </a>

          <span>Job hunting re-defined.</span>

          <ul
            className="none check my-40"
            style={{
              color: "#26bdaa",
              fontWeight: "bold",
              fontSize: "larger",
              textAlign: "center",
            }}
          >
            <li>Not just companies - jobs have reviews.</li>
            <li>Get interviews faster</li>
            <li>Filter ghost jobs in seconds.</li>
          </ul>

          <hr />
        </div>
        <div className="mobile-ad-tile-orange" style={{}}>
          <div>
            <strong style={{ color: "darkcyan", fontSize: "x-large" }}>
              {" "}
              ExpenseHut
            </strong>
          </div>

          <div>
            <p>
              Built and scaled to thousands of users across the globe,
              ExpenseHut is a personal finance dashboard.
            </p>
          </div>
        </div>

        <div className="mobile-ad-tile-blue" style={{}}>
          <div>
            <a href="https://www.recruit.expensehut.com" target="_blank">
              <strong>Recruit</strong>
            </a>
          </div>

          <div className="divCenterAlign mt-10">
            <strong>Are you a job seeker?</strong>
          </div>

          <div>
            <p>
              Try{" "}
              <a href="https://www.recruit.expensehut.com" target="_blank">
                Recruit
              </a>{" "}
              - the next-gen job portal based on user reviews.
            </p>
          </div>
        </div>

        <div>
          <strong>Do you enjoy writing articles in the web?</strong>
          <p>
            With{" "}
            <a href="https://www.blogs.expensehut.com" target="_blank">
              Blogs
            </a>{" "}
            you can write and publish your article for free!
          </p>
        </div>

        <hr />

        <div className="mobile-ad-tile-green" style={{}}>
          <div>
            <strong style={{ color: "brown" }}>
              {" "}
              Interested to learn more?{" "}
            </strong>
          </div>

          <div className="my-20">
            <Link to="">Sign up</Link> here!
          </div>

          <div>
            <p>We do not share or sell your data to anyone.</p>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}
