import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { loginSuccess } from "../../actions/userAction";
import * as AppConfigHelper from "../../helpers/AppConfigHelper";
import { downloadAndLoadGSIScript } from "../../helpers/ResourceHelper";
import { getGoogleClientId } from "../../services/FNXConnection";
import {
  registerUser,
  registerWithSSO,
  verifyRegistration,
} from "../../services/RegisterService";
import store from "../../store";
import TextInputComponent2 from "../shared/TextInputComponent2";

import { useFormik } from "formik";
import * as Yup from "yup";
import StatusMessageComponent2 from "../shared/StatusMessageComponent2";
import DYKComponent from "./DYKComponent";
import { LoadingButton } from "../shared/ButtonComponent";

export function RegisterComponent({ isMobile }) {
  const queryparams = new URLSearchParams(window.location.search);

  const [statusMsg, setStatusMsg] = useState();
  const candidateId = queryparams.get("cid");
  const regkey = queryparams.get("regkey");
  const email = queryparams.get("email");
  const [source, setSource] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
          "Please enter valid email address",
        )
        .required("Please enter email."),
      password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Must contain atleast 1 special character, 1 number and 8 chars minimum.",
        )
        .min(8, "Minimum of 8 characters")
        .required("Password is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      registerUser(values.email, values.password)
        .then(() => {
          setStatusMsg({
            type: "success",
            text:
              "A verification link has been sent to your email." +
              " Please follow the link to complete the registration.",
          });
        })
        .catch((err) => {
          if (err.status === 400) {
            setStatusMsg({
              type: "error",
              text:
                "This user is already registered. If you have not confirmed your registration, " +
                "please check your email.",
            });
          } else if (err.status === 403) {
            setStatusMsg({
              type: "error",
              component: () => (
                <>
                  {" "}
                  This email or domain is blocked. If you think this is a
                  mistake, please{" "}
                  <a href="mailto:expensehut@gmail.com">contact us</a>.{" "}
                </>
              ),
            });
          } else {
            console.error(err);
            setStatusMsg({
              type: "error",
              text: "Error during registration. Please try again or contact the admin.",
            });
          }
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    },
  });

  const registerSSOHandler = useCallback(
    (responseFromGmailAuth) => {
      // result is user object.
      registerWithSSO(responseFromGmailAuth.credential)
        .then((result) => {
          const { access_token: token } = result;

          if (token) {
            if (isMobile) {
              setStatusMsg({
                type: "success",
                component: () => (
                  <>
                    {" "}
                    Registration successful. Please{" "}
                    <Link to="/login">Login</Link> to continue{" "}
                  </>
                ),
              });
            } else {
              AppConfigHelper.setSignature(result.sign);
              store.dispatch(loginSuccess(result));
            }
          } else {
            setStatusMsg({
              type: "error",
              text: `Whoops! Something went wrong on our side. Please try after sometime or contact the admin.`,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.status === 409) {
            setStatusMsg({
              type: "error",
              component: () => (
                <>
                  Whoops! This user already exists in the system. Please{" "}
                  <Link to="/login">Login</Link>{" "}
                </>
              ),
            });
          } else if (err.status === 403) {
            setStatusMsg({
              type: "error",
              text: `Sorry! This email domain is not allowed for registration. Please try with different email address or contact the admin.`,
            });
          } else {
            setStatusMsg({
              type: "error",
              text: `Whoops! Our servers appears to be down. The admin is notified. Please try later.`,
            });
          }
        });
    },
    [queryparams],
  );

  useEffect(() => {
    downloadAndLoadGSIScript()
      .then(() => {
        window.google.accounts.id.initialize({
          client_id: getGoogleClientId(),
          callback: (response) => registerSSOHandler(response),
        });
        window.google.accounts.id?.renderButton(
          document.querySelector(".sso-signin"),
          { theme: "dark", size: "medium", text: "signup_with" }, // customization attributes
        );
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    const source = queryparams.get("source");
    if (source) {
      setSource(source);
    }
  }, [queryparams]);

  useEffect(() => {
    if (candidateId && regkey) {
      verifyRegistration(candidateId, regkey, email)
        .then(() => {
          setStatusMsg({
            type: "success",
            component: () => (
              <>
                {" "}
                Registration successful. Please <Link to="/login">
                  Login
                </Link>{" "}
                to continue{" "}
              </>
            ),
          });
        })
        .catch(() => {
          setStatusMsg({
            type: "error",
            text: `Whoops! The registration link has expired or invalid. Please try again or contact the admin.`,
          });
        });
    }
  }, [candidateId, regkey]);

  return (
    <div className={`divFlex p-40 ${isMobile ? "divColumn" : ""}`}>
      {isMobile && (
        <div className="divCenterAlign">
          <Link to="/">
            <img
              src={"/assets/logo.png"}
              width={100}
              style={{ borderRadius: 20 }}
            />
          </Link>
        </div>
      )}

      <div className={`divColumn ${isMobile ? "w-100" : "w-30"}`}>
        <div className="title mx-10" style={{ textAlign: "left" }}>
          <span>Register </span>
        </div>

        <div className="p-8">
          {statusMsg && <StatusMessageComponent2 message={statusMsg} />}
        </div>

        <div className=" p-16">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-8">
              <div className="my-0">
                <div>
                  <span style={{ fontSize: "larger" }}>Email</span>

                  <TextInputComponent2
                    id="email"
                    type="text"
                    width={300}
                    errorid="email-error"
                    {...formik.getFieldProps("email")}
                    error={formik.errors.email}
                  />
                </div>

                <div className={"my-80"}>
                  <span style={{ fontSize: "larger" }}>Password</span>

                  <TextInputComponent2
                    id="password"
                    type="password"
                    width={300}
                    errorid="password-error"
                    {...formik.getFieldProps("password")}
                    error={formik.errors.password}
                  />
                </div>
              </div>
            </div>

            <div className="divColumn p-10">
              <LoadingButton
                id="registerBtn"
                loading={isLoading}
                value={"Register"}
                onClick={formik.handleSubmit}
              />

              <Link to="/login">Already Registered?</Link>
            </div>

            {source !== "recruit" && (
              <div className="my-40">
                <div className="sso-signin" style={{ width: "200px" }}></div>
              </div>
            )}

            <div className="my-40">
              <a
                style={{ fontSize: "large" }}
                target="_blank"
                href="https://help.expensehut.com/terms"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
            </div>
          </form>
        </div>
      </div>

      <DYKComponent width={isMobile ? "100%" : "70%"} />
    </div>
  );
}
