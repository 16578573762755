import React from "react";
import "./WelcomeComponent.css";
import PropTypes from "prop-types";

const WelcomeListTextComponent = ({ value, subtitle }) => {
  return (
    <div className="divColumn">
      <h2 style={{ fontSize: "25px" }}>{value}</h2>

      <div className="mt-10">
        <span>
          {subtitle?.value}
          {subtitle?.sup && <sup>*</sup>}
        </span>
      </div>
    </div>
  );
};

export default class WelcomeComponentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUnSupportedBrowser: true,
    };
  }

  componentDidMount() {
    const useragent = navigator.userAgent;
    const isChrome =
      useragent &&
      (useragent.includes("Chrome/") || useragent.includes("Safari/")) &&
      !useragent.includes("Edg/");
    this.setState({ isUnSupportedBrowser: isChrome !== true });
  }

  render() {
    return (
      <div>
        <div>
          <table style={{ borderSpacing: "2em" }} className={"welcome-table"}>
            <tbody>
              <tr style={{ textAlign: "center", marginBottom: "2%" }}>
                <td>
                  <WelcomeListTextComponent
                    value="Connect to bank accounts"
                    subtitle={{
                      value: "Transactions are added automatically everyday",
                    }}
                  />
                </td>
                <td>
                  <img src="assets/charts.png" width="50%" />
                </td>
              </tr>

              <tr style={{ textAlign: "center", marginBottom: "2%" }}>
                <td>
                  <img src="assets/icons/util-mobile.svg" width="50%" />
                </td>
                <td>
                  <WelcomeListTextComponent
                    value="Text BAL to +1 (855) 934-1064"
                    subtitle={{
                      value:
                        "Check balance of all your accounts in one simple text.",
                      sup: true,
                    }}
                  />
                </td>
              </tr>

              <tr style={{ textAlign: "center", marginBottom: "2%" }}>
                <td>
                  <WelcomeListTextComponent value="Create, manage and organize in groups." />
                </td>
                <td>
                  <img src="assets/people.png" width="35%" />
                </td>
              </tr>

              <tr style={{ textAlign: "center", marginBottom: "2%" }}>
                <td>
                  <img src="assets/receipt.png" width="70%" />
                </td>
                <td>
                  <WelcomeListTextComponent value="Scan receipts on our mobile apps." />
                </td>
              </tr>

              <tr style={{ textAlign: "center", marginBottom: "2%" }}>
                <td>
                  <WelcomeListTextComponent
                    value="Live forex rates."
                    subtitle={{
                      value:
                        "Get to know how much your savings is worth in other currencies.",
                    }}
                  />
                </td>
                <td>
                  <img src="assets/savings.png" width="30%" />
                </td>
              </tr>

              <tr style={{ textAlign: "center", marginBottom: "2%" }}>
                <td>
                  <img src="assets/icons/schedule.png" width="50%" />
                </td>
                <td>
                  <WelcomeListTextComponent value="Monthly & weekly reports." />
                </td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>
                  <WelcomeListTextComponent value="Manage trip expenses." />
                </td>

                <td>
                  <img src="assets/travel.png" width="35%" />
                </td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>
                  <img src="assets/analysis.png" width="65%" />
                </td>
                <td>
                  <WelcomeListTextComponent value="Analytical views and charts" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <sup>*</sup> Available only to US customers.
        </div>
      </div>
    );
  }
}
