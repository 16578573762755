import React from "react";
import { Link } from "react-router-dom";
import FooterComponent from "./FooterComponent";
import "./LeftPaneComponent.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PropTypes from "prop-types";

const ExternalLinkComponent = ({ url, text }) => {
  return (
    <>
      <a href={`${url}`} target="_blank" rel="noreferrer">
        {text}
      </a>
      <OpenInNewIcon sx={{ width: "20px", marginLeft: "3px" }}></OpenInNewIcon>
    </>
  );
};

ExternalLinkComponent.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
};

export default class LeftPaneMobileComponent extends React.Component {
  render() {
    return (
      <div className="left-pane-container">
        <div>
          <div className="logo">
            <Link to="/">
              <img src="/assets/logo.png" />
            </Link>
          </div>

          <div id="menu">
            <ul>
              <li className="divCenter">
                <ExternalLinkComponent
                  url="https://help.expensehut.com"
                  text="Help"
                />
              </li>
              <li className="divCenter">
                <a href="https://www.blogs.expensehut.com">Blogs</a>
              </li>

              <li className="divCenter">
                <a
                  href="https://help.expensehut.com/privacy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy
                </a>
                <OpenInNewIcon
                  sx={{ width: "20px", marginLeft: "3px" }}
                ></OpenInNewIcon>
              </li>
              <li className="divCenter">
                <a
                  href="https://help.expensehut.com/contactUs"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Us
                </a>
                <OpenInNewIcon
                  sx={{ width: "20px", marginLeft: "3px" }}
                ></OpenInNewIcon>
              </li>
            </ul>
          </div>
        </div>
        <FooterComponent />
      </div>
    );
  }
}
