import React from "react";
import PauseIcon from "@mui/icons-material/Pause";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import PropTypes from "prop-types";

const BUTTON_SIZE = 40;
const SLIDES_COUNT = 4;

export default class LaunchSlideComponent extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      isPaused: false,
      slideIndex: 1,
    };
    this.startSlideShow = this.startSlideShow.bind(this);
    this.navigateRight = this.navigateRight.bind(this);
    this.navigateLeft = this.navigateLeft.bind(this);
  }
  componentDidMount() {
    this.mounted = true;
    this.startSlideShow();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  startSlideShow() {
    if (this.mounted && !this.state.isPaused) {
      let _index = this.state.slideIndex + 1;
      if (_index > SLIDES_COUNT) {
        _index = 1;
      }

      !this.state.isPaused &&
        setTimeout(
          () =>
            !this.state.isPaused &&
            this.setState({ slideIndex: _index }, this.startSlideShow),
          2000,
        );
    }
  }

  navigateRight() {
    let _index = this.state.slideIndex + 1;
    if (_index > SLIDES_COUNT) {
      _index = 1;
    }
    this.setState({ slideIndex: _index });
  }

  navigateLeft() {
    let _index = this.state.slideIndex - 1;
    if (_index < 1) {
      _index = SLIDES_COUNT;
    }
    this.setState({ slideIndex: _index });
  }

  render() {
    return (
      <div style={{ margin: "40px 0px" }}>
        <div
          id="slide-control"
          className="divCenterAlign"
          style={{ margin: "10px 0px" }}
        >
          <div className="control-button">
            <SkipPreviousIcon
              sx={{
                width: BUTTON_SIZE,
                height: BUTTON_SIZE,
                cursor: "pointer",
              }}
              color={
                this.state.isPaused && this.state.slideIndex > 1
                  ? "primary"
                  : "disabled"
              }
              onClick={
                this.state.isPaused && this.state.slideIndex > 1
                  ? this.navigateLeft
                  : undefined
              }
            />
          </div>

          <div className="control-button">
            {this.state.isPaused && (
              <NotStartedIcon
                sx={{
                  width: BUTTON_SIZE,
                  height: BUTTON_SIZE,
                  cursor: "pointer",
                }}
                color={"primary"}
                onClick={() =>
                  this.setState({ isPaused: false }, this.startSlideShow)
                }
              />
            )}
            {!this.state.isPaused && (
              <PauseIcon
                sx={{
                  width: BUTTON_SIZE,
                  height: BUTTON_SIZE,
                  cursor: "pointer",
                }}
                color={"primary"}
                onClick={() => this.setState({ isPaused: true })}
              />
            )}
          </div>

          <div className="control-button">
            <SkipNextIcon
              sx={{
                width: BUTTON_SIZE,
                height: BUTTON_SIZE,
                cursor: "pointer",
              }}
              color={
                this.state.isPaused && this.state.slideIndex < SLIDES_COUNT
                  ? "primary"
                  : "disabled"
              }
              onClick={
                this.state.isPaused && this.state.slideIndex < SLIDES_COUNT
                  ? this.navigateRight
                  : undefined
              }
            />
          </div>
        </div>

        <div
          style={{
            backgroundColor: "white",
            height: this.props.isMobile ? window.innerHeight / 1.5 : "500px",
            padding: "10px 40px",
            boxShadow: "10px 5px 5px var(--primary-light)",
          }}
        >
          {this.state.slideIndex === 1 && (
            <div id="slide-1" className="slide-component">
              <div
                style={{
                  flex: 1.5,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    color: "var(--fnx-total-green)",
                    fontSize: "large",
                    fontWeight: "bold",
                  }}
                >
                  ExpenseHut - Custom tailored software applications for
                  financial management.
                </span>

                <div className="section">
                  <a href="./welcome">ExpenseHut</a>
                  <span>
                    Web and mobile apps to manage your expenses, savings and
                    loans.
                  </span>
                </div>

                <div className="section">
                  <a
                    href="https://wallet.expensehut.com"
                    target="_blank"
                    rel="no-referrer noreferrer"
                  >
                    {" "}
                    Wallet{" "}
                  </a>
                  <span> Split bills and expenses between users. </span>
                </div>

                <div className="section">
                  <a
                    href="https://www.blogs.expensehut.com"
                    target="_blank"
                    rel="no-referrer noreferrer"
                  >
                    {" "}
                    Blogs
                  </a>
                  <span> Publish unlimited articles in the web for free! </span>
                </div>
              </div>

              {!this.props.isMobile && (
                <div style={{ flex: 1 }}>
                  <img
                    src="assets/launch-logo.png"
                    width="100%"
                    style={{
                      animationName: "fadeinstatus",
                      animationDuration: "2s",
                      opacity: 1,
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {this.state.slideIndex === 2 && (
            <div id="slide-2" className="slide-component">
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="section">
                  <a href="./welcome">ExpenseHut</a>
                  <p>
                    A one-stop shopping app to manage your expenses, savings,
                    loans and content.
                  </p>
                </div>

                <div className="section">
                  <ul>
                    <li>
                      <strong className="section-title"> Type less </strong>-
                      Use our intel mode to add an expense as little as in 5
                      secs.
                    </li>
                    <li>
                      <strong className="section-title">
                        Live currency rates{" "}
                      </strong>
                      - Check how much your savings is worth in foreign
                      currency.
                    </li>
                    <li>
                      <strong className="section-title">Receipts Scan </strong>{" "}
                      - snap and tap to add an expense.
                    </li>
                    <li>
                      <strong className="section-title"> Mobile</strong>{" "}
                      Supported in Android and iOS.
                    </li>
                  </ul>
                </div>
              </div>

              {!this.props.isMobile && (
                <div style={{ flex: 1 }} className="animate-right">
                  <img src="assets/launch-expensehut.png" width="90%" />
                </div>
              )}
            </div>
          )}

          {this.state.slideIndex === 3 && (
            <div
              id="slide-3"
              className="slide-component"
              style={{ display: "flex", height: "80%" }}
              hidden={this.state.slideIndex !== 3}
            >
              {!this.props.isMobile && (
                <div
                  style={{ flex: 1, margin: "0px 10px" }}
                  className="animate-zoom divCenterAlign"
                >
                  <img
                    src="assets/launch-wallet-logo.png"
                    width={this.props.isMobile ? "100%" : "50%"}
                    style={{ borderRadius: 20 }}
                  />
                </div>
              )}

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: "0px 10px",
                }}
              >
                <div className="section">
                  <a
                    href="https://wallet.expensehut.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Wallet
                  </a>
                  <p>Split bills and expenses.</p>
                  <p>
                    One login - Sign in using same credentials. No new
                    registration required.
                  </p>
                </div>
              </div>
            </div>
          )}

          {this.state.slideIndex === 4 && (
            <div
              id="slide-3"
              className="slide-component"
              style={{ display: "flex", height: "80%" }}
              hidden={this.state.slideIndex !== 3}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className="section">
                  <a
                    href="https://www.blogs.expensehut.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blogs
                  </a>
                  <p>
                    Stop paying to read articles! Create your own content and
                    publish in the web for Free!
                  </p>
                </div>

                <div className="section">
                  <ul>
                    <li>
                      Write, write, write! Create as many articles you want.
                    </li>
                    <li>
                      One login - use Expensehut login. No new registration
                      required.
                    </li>
                  </ul>
                </div>
              </div>

              <div style={{ width: "50%" }} className="animate-right divCenter">
                <span
                  style={{ fontSize: this.props.isMobile ? "10vw" : "5vw" }}
                >
                  {" "}
                  Blogs{" "}
                </span>
                <span
                  style={{ fontSize: this.props.isMobile ? "2.5vw" : "1vw" }}
                >
                  {" "}
                  By ExpenseHut{" "}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

LaunchSlideComponent.propTypes = {
  isMobile: PropTypes.bool,
};
