import React, { useEffect, useState } from "react";
import { getPriyaIcon, getSabarishIcon } from "../../helpers/IconHelper";
import { Link } from "react-router-dom";

export default function TeamBadge() {
  const [index, setIndex] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setIndex(index === 0 ? 1 : 0);
    }, 2500);
  }, [index]);

  return (
    <div>
      {index === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            animationName: "fadeinstatus",
            animationDuration: "2s",
            opacity: 1,
          }}
        >
          <i style={{ color: "var(--fnx-dark-grey)" }}>
            {" "}
            &quot; Expensehut is driven by users like you. It's free! &quot;{" "}
          </i>

          <a
            href="https://www.sabarishnarain.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={getSabarishIcon()}
              width={70}
              style={{ borderRadius: 45, marginLeft: 10 }}
            />
          </a>
        </div>
      )}
      {index === 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            animationName: "fadeinstatus",
            animationDuration: "2s",
            opacity: 1,
          }}
        >
          <i style={{ color: "var(--fnx-dark-grey)" }}>
            {" "}
            &quot; Data is the key! We securely handle it to give you the best
            experience. &quot;{" "}
          </i>

          <Link to="/teams/priya">
            <img
              src={getPriyaIcon()}
              width={70}
              style={{ borderRadius: 45, marginLeft: 10 }}
            />
          </Link>
        </div>
      )}
    </div>
  );
}
