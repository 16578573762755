export function getIconForCategorySubcategory(subcategory, category) {
  let icon = "banknote.svg";
  if (category === "BEAUTY") {
    icon = "makeup.svg";
  } else if (category === "CAR") {
    if (subcategory === "GAS") {
      icon = "gas-station.svg";
    } else if (subcategory === "INSURANCE") {
      icon = "car-insurance.svg";
    } else if (subcategory === "PAYMENTS") {
      icon = "car-loan.svg";
    }
  } else if (category === "DONATIONS") {
    icon = "donation.svg";
  } else if (category === "ENTERTAINMENT") {
    icon = "entertainment.svg";
  } else if (category === "FOOD") {
    icon = "food-other.svg";
    if (subcategory === "EATOUTS") {
      icon = "eatouts.svg";
    } else if (subcategory === "GROCERIES") {
      icon = "grocery-cart.svg";
    } else if (subcategory === "DRINKS") {
      icon = "wine.svg";
    }
  } else if (category === "FITNESS") {
    if (subcategory === "FITNESS") {
      icon = "fitness.svg";
    }
  } else if (category === "HOUSING") {
    icon = "house.svg";
    if (subcategory === "REPAIR" || subcategory === "HANDYMAN") {
      icon = "repair.svg";
    }
  } else if (category === "INVESTMENTS") {
    icon = "investments.svg";
  } else if (category === "MEDICAL") {
    icon = "doctor.svg";
  } else if (category === "SHOPPING") {
    icon = "shopping.svg";
  } else if (category === "TRAVEL") {
    icon = "travel.svg";
  } else if (category === "UTILITY") {
    if (subcategory === "TV") {
      icon = "util-tv.svg";
    } else if (subcategory === "MOBILE") {
      icon = "util-mobile.svg";
    } else if (subcategory === "WATER") {
      icon = "water.svg";
    } else if (subcategory === "TRASH") {
      icon = "trash.svg";
    } else if (subcategory === "HEAT") {
      icon = "heater.svg";
    } else if (subcategory === "INTERNET") {
      icon = "world-wide-web.svg";
    } else if (subcategory === "TELEPHONE") {
      icon = "telephone.svg";
    }
  } else if (category === "VACATION") {
    icon = "vacation.svg";
  }
  return `/assets/icons/${icon}`;
}

export const getHelpIcon = () => `/assets/icons/info.png`;
export const getSuccessIcon = () => `/assets/checked.png`;
export const getEmptyBox = () => `/assets/box.png`;

export const getLeftNavArrow = () => `/assets/icons/left-arrow.png`;
export const getRightNavArrow = () => `/assets/icons/right-arrow.png`;

export const getDeleteIcon = () => `/assets/icons/delete.png`;

export const getGroupsIcon = () => `/assets/icons/groups.svg`;

export const getWarningIcon = () => `/assets/warning.png`;

export const getShieldIcon = () => `/assets/shield.png`;

export const getClockIcon = () => `/assets/clock.png`;

export const getManIcon = () => `/assets/icons/man.svg`;

export const getProfitsIcon = () => `/assets/icons/profits.svg`;

export const getSabarishIcon = () => `/assets/sabarish.jpeg`;
export const getPriyaIcon = () => `/assets/priya.jpeg`;
export const getPriyaProfileIcon = () => `/assets/priya-main-profile.jpg`;

export const getCityScapeIcon = () => `/assets/icons/cityscape.png`;
