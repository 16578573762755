import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  Drawer,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  getManIcon,
  getProfitsIcon,
  getSabarishIcon,
  getShieldIcon,
} from "../../helpers/IconHelper";
import FooterComponent from "../FooterComponent";
import LeftPaneMobileComponent from "../LeftPaneMobileComponent";
import "../WelcomeComponent.css";
import "./LaunchComponent.css";
import LaunchSlideComponent from "./LaunchSlideComponent";
import MobileAvertisementListComponent from "./MobileAvertisementListComponent";
import TeamBadge from "./team-badge.component";

export default class MobileLaunchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  render() {
    return (
      <div id="launch-component">
        {
          <Drawer
            anchor={"left"}
            open={this.state.showOptions}
            sx={{ width: 20 }}
            onClose={() => this.setState({ showOptions: false })}
          >
            <LeftPaneMobileComponent />
          </Drawer>
        }

        <div>
          <div className="title topMargin1P p-4">Expense Hut</div>

          <div className={"divSpread my-20"}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() =>
                this.setState({ showOptions: !this.state.showOptions })
              }
              edge="start"
            >
              <MenuIcon />
            </IconButton>

            <div className="mt-10">
              <Link to="/register" className="bigButtonGreen">
                Sign up - Free!
              </Link>
            </div>
          </div>
        </div>

        <hr className="my-20" />

        <div>
          <div className="launch-tagline">Learn, Build & Iterate</div>

          <TeamBadge />

          <hr />

          <MobileAvertisementListComponent />

          <LaunchSlideComponent isMobile={true} />

          <hr className="my-20" />

          <div className="body-footer">
            <div className={`body-footer-item divColumn`}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="exhut type-less" src={getManIcon()} />
                </ListItemAvatar>
                <ListItemText
                  primary="Multiple apps. One identity."
                  primaryTypographyProps={{ fontSize: "larger" }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {"Use same credentials to access all our apps."}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>

              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="exhut type-less" src={getProfitsIcon()} />
                </ListItemAvatar>
                <ListItemText
                  primary="Awesome features for free!"
                  primaryTypographyProps={{ fontSize: "larger" }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {
                          "Sign up to experience the great features without paying a dime."
                        }
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>

              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="exhut type-less" src={getShieldIcon()} />
                </ListItemAvatar>
                <ListItemText
                  primary="Data security"
                  primaryTypographyProps={{ fontSize: "larger" }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {
                          "All information is secured. You access our services through SSL."
                        }
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </div>
          </div>

          <FooterComponent />
        </div>
      </div>
    );
  }
}
