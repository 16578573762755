import React from "react";
import "./LeftPaneComponent.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getSignature } from "../helpers/AppConfigHelper";
import FooterComponent from "./FooterComponent";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
export default class LeftPaneComponent extends React.Component {
  render() {
    return (
      <div className="left-pane-container">
        <div>
          <div className="logo">
            <Link to="/home">
              <img src="/assets/logo.png" />
            </Link>
          </div>

          <div id="menu">
            {!this.props.authenticated && (
              <ul>
                <li>
                  {" "}
                  <Link to="/register">Register</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/login">Login</Link>
                </li>
                <li>
                  <div className="divCenterAlign">
                    <a
                      href="https://www.recruit.expensehut.com"
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: 5 }}
                    >
                      Recruit
                    </a>
                    <OpenInNewIcon sx={{ fontSize: 18 }} />
                  </div>
                </li>
                <li>
                  <div className="divCenterAlign">
                    <a
                      href="https://www.blogs.expensehut.com"
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: 5 }}
                    >
                      Blogs
                    </a>
                    <OpenInNewIcon sx={{ fontSize: 18 }} />
                  </div>
                </li>
                <li>
                  <div className="divCenterAlign">
                    <a
                      href="https://www.wallet.expensehut.com"
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: 5 }}
                    >
                      Wallet
                    </a>
                    <OpenInNewIcon sx={{ fontSize: 18 }} />
                  </div>
                </li>

                <li>
                  {" "}
                  <a href="mailto:expensehut@gmail.com">Contact Us</a>
                </li>
              </ul>
            )}
            {this.props.authenticated && (
              <ul>
                <li>
                  {" "}
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/addExpense">Add Expense</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/savings">Savings</Link>
                </li>

                <li>
                  {" "}
                  <Link to="/analysis">Analysis</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/settings">Settings</Link>
                </li>
                <li>
                  {" "}
                  <a
                    href={`https://wallet.expensehut.com?exhut_token_ssid=${getSignature()}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Wallet
                  </a>
                </li>
                <li>
                  {" "}
                  <Link onClick={this.props.onClickLogout} to="/login?logout=1">
                    Logout
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
        <FooterComponent />
      </div>
    );
  }
}

LeftPaneComponent.propTypes = {
  user: PropTypes.object,
  onClickLogout: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
};
