import React from "react";
import "./LeftPaneComponent.css";

export default class FooterComponent extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-copyright"> &copy; 2024 www.expensehut.com </div>
        <div>
          {" "}
          A{" "}
          <a
            target="_blank"
            rel="noreferrer"
            style={{ color: "yellow" }}
            href="https://www.sabarishnarain.com"
          >
            SabarishNarain{" "}
          </a>{" "}
          Project{" "}
        </div>
      </div>
    );
  }
}
